import {
  getUnApproveCompany,
  approveCompany,
  getApprovedPendingCompany,
  deleteCompany,
} from "api/admin";
import Card from "components/card";
import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { rejectCompany } from "api/admin";
const CompaniesApprove = () => {
  const [activeTab, setActiveTab] = useState("pending");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [unapprovedCompanies, setUnapprovedCompanies] = useState([]);
  const [approved, setApproved] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [rejectCompanyId, setRejectCompanyId] = useState(null);

  const [selectedDeleteCompanyId, setSelectedDeleteCompanyId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUnApproveCompany();
        console.log(response.data.data);
        setUnapprovedCompanies(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching unapproved companies:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("jwttoken");
        const response = await getApprovedPendingCompany(token);
        console.log(response.data.data);
        setApproved(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching unapproved companies:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleApprove = async (companyId) => {
    setSelectedCompanyId(companyId);
  };

  const handleReject = async (companyId) => {
    setRejectCompanyId(companyId);
  };

  const handleDelete = async (companyId) => {
    setSelectedDeleteCompanyId(companyId);
    console.log("delete console");
  };

  const confirmApprove = async () => {
    try {
      const token = localStorage.getItem("jwttoken");

      await approveCompany(selectedCompanyId);
      setUnapprovedCompanies((prevCompanies) =>
        prevCompanies.filter((company) => company._id !== selectedCompanyId)
      );
      setSelectedCompanyId(null);
      const approvedResponse = await getApprovedPendingCompany(token);
      setApproved(approvedResponse.data.data);
      toast.success("Unternehmen erfolgreich zugelassen!");
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };
  const confirmReject = async () => {
    try {
      const token = localStorage.getItem("jwttoken");

      const response = await rejectCompany(rejectCompanyId);
      setUnapprovedCompanies((prevCompanies) =>
        prevCompanies.filter((company) => company._id !== rejectCompanyId)
      );
      console.log(response);
      setRejectCompanyId(null);
      const approvedResponse = await getApprovedPendingCompany(token);
      setApproved(approvedResponse.data.data);
      toast.success("Unternehmen erfolgreich abgelehnt!");
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("jwttoken");

      await deleteCompany(selectedDeleteCompanyId, token);
      setApproved((prevCompanies) =>
        prevCompanies.filter(
          (company) => company._id !== selectedDeleteCompanyId
        )
      );
      setSelectedDeleteCompanyId(null);
      toast.success("Unternehmen erfolgreich gelöscht!");
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Card extra={"w-full h-full p-4 overflow-hidden overflow-x-auto"}>
      <div className="container mx-auto mt-8">
        <div className="flex space-x-4 overflow-hidden overflow-x-auto">
          <button
            className={`${
              activeTab === "pending" ? "bg-blue-500 text-white" : "bg-gray-300"
            } rounded px-4 py-2`}
            onClick={() => handleTabClick("pending")}
          >
            Ausstehend
          </button>
          <button
            className={`${
              activeTab === "approved"
                ? "bg-green-500 text-white"
                : "bg-gray-300"
            } rounded px-4 py-2`}
            onClick={() => handleTabClick("approved")}
          >
            Genehmigt
          </button>
          <button
            className={`${
              activeTab === "rejected" ? "bg-red-500 text-white" : "bg-gray-300"
            } rounded px-4 py-2`}
            onClick={() => handleTabClick("rejected")}
          >
            Abgelehnt
          </button>
        </div>

        {/* Render content based on the active tab */}
        {activeTab === "pending" && (
          <table className="mt-4 max-h-[70vh] w-full overflow-x-auto overflow-y-auto">
            <thead>
              <tr className="bg-green-200">
                <th className="px-4 py-2 text-white ">#</th>
                <th className="px-4 py-2 text-white ">Name</th>
                <th className="px-4 py-2 text-white">E-Mail</th>

                <th className="px-4 py-2 text-white">Aktion</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="py-4 text-center">
                    Laden...
                  </td>
                </tr>
              ) : (
                unapprovedCompanies
                  .filter(
                    (company) =>
                      company.approved === false && company.pending === true
                  )
                  .map((approvedCompany, index) => (
                    <tr
                      key={approvedCompany._id}
                      className=" border-b border-gray-300 text-center "
                    >
                      <td className="px-4 py-2 ">{index + 1}</td>
                      <td className="px-4 py-2 ">
                        {approvedCompany.firstName}
                      </td>
                      <td className="px-4 py-2 ">{approvedCompany.email}</td>

                      <td className="px-4 py-2 ">
                        <button
                          onClick={() => handleApprove(approvedCompany._id)}
                          className="mx-2 rounded bg-green-500 px-1 py-1 text-white hover:bg-green-600"
                        >
                          Genehmigen
                        </button>
                        <button
                          onClick={() => handleReject(approvedCompany._id)}
                          className="mx-2 rounded bg-red-500 px-1 py-1 text-white hover:bg-red-600"
                        >
                          Ablehnen
                        </button>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        )}

        {activeTab === "approved" && (
          <table className="mt-4 max-h-[70vh] w-full overflow-x-auto overflow-y-auto">
            <thead>
              <tr className="bg-green-200">
                <th className="px-4 py-2 text-white">#</th>
                <th className="px-4 py-2 text-white">Name</th>
                <th className="px-4 py-2 text-white">E-Mail</th>

                <th className="px-4 py-2 text-white">Aktion</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="py-4 text-center">
                    Laden...
                  </td>
                </tr>
              ) : (
                approved
                  .filter((company) => company.approved === true)
                  .map((approvedCompany, index) => (
                    <tr
                      key={approvedCompany._id}
                      className=" border-b border-gray-300 text-center "
                    >
                      <td className="px-4 py-2 ">{index + 1}</td>
                      <td className="px-4 py-2 ">
                        {approvedCompany.firstName}
                      </td>
                      <td className="px-4 py-2 ">{approvedCompany.email}</td>

                      <td className="px-4 py-2 ">
                        <button
                          onClick={() => handleDelete(approvedCompany._id)}
                          className="rounded bg-red-500 px-2 py-1 text-white hover:bg-red-600"
                        >
                          Löschen
                        </button>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        )}

        {activeTab === "rejected" && (
          <table className="mt-4 max-h-[70vh] w-full overflow-x-auto overflow-y-auto">
            <thead>
              <tr className="bg-brand-50">
                <th className="px-4 py-2 text-white">#</th>
                <th className="px-4 py-2 text-white">Name</th>
                <th className="px-4 py-2 text-white">E-Mail</th>

                <th className="px-4 py-2 text-white">Aktion</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="py-4 text-center">
                    Laden...
                  </td>
                </tr>
              ) : (
                unapprovedCompanies
                  .filter((company) => company.pending === false)
                  .map((approvedCompany, index) => (
                    <tr
                      key={approvedCompany._id}
                      className=" border-b border-gray-300 text-center "
                    >
                      <td className="px-4 py-2">{index + 1}</td>
                      <td className="px-4 py-2">{approvedCompany.firstName}</td>
                      <td className="px-4 py-2">{approvedCompany.email}</td>

                      <td className="px-4 py-2">
                        <button
                          onClick={() => handleDelete(approvedCompany._id)}
                          className="rounded bg-red-500 px-2 py-1 text-white hover:bg-red-600"
                        >
                          Löschen
                        </button>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        )}
      </div>

      {/* Modal for delete confirmation */}
      <Transition show={selectedDeleteCompanyId !== null} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setSelectedDeleteCompanyId(null)}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      {/* Heroicon name: outline/check */}
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Bestätigen Löschen
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Sind Sie sicher, dass Sie dieses Unternehmen löschen
                          wollen?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={confirmDelete}
                    type="button"
                    className="border-transparent inline-flex w-full justify-center rounded-md border bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Löschen
                  </button>
                  <button
                    onClick={() => setSelectedDeleteCompanyId(null)}
                    type="button"
                    className="hover:bg-gray-50 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Abbrechen
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      {/* Modal for Approve confirmation */}
      <Transition show={selectedCompanyId !== null} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setSelectedCompanyId(null)}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      {/* Heroicon name: outline/check */}
                      <svg
                        className="h-6 w-6 text-green-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Genehmigung bestätigen
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Sind Sie sicher, dass Sie dieses Unternehmen zulassen
                          wollen?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={confirmApprove}
                    type="button"
                    className="border-transparent inline-flex w-full justify-center rounded-md border bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Genehmigen
                  </button>
                  <button
                    onClick={() => setSelectedCompanyId(null)}
                    type="button"
                    className="hover:bg-gray-50 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Abbrechen
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Modal for Reject confirmation */}
      <Transition show={rejectCompanyId !== null} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setRejectCompanyId(null)}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      {/* Heroicon name: outline/check */}
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Bestätigen Ablehnen
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Sind Sie sicher, dass Sie dieses Unternehmen ablehnen
                          wollen?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={confirmReject}
                    type="button"
                    className="border-transparent inline-flex w-full justify-center rounded-md border bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Ablehnen
                  </button>
                  <button
                    onClick={() => setRejectCompanyId(null)}
                    type="button"
                    className="hover:bg-gray-50 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Abbrechen
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Card>
  );
};

export default CompaniesApprove;
