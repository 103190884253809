import React from "react";
import InfoHubMain from "./components/InfoHubMain";

const InfoHub = () => {
  return (
    <>
      <InfoHubMain />
    </>
  );
};

export default InfoHub;
