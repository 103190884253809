import React from "react";
import OccupationTable from "./OccupationTable";

const Occupation = () => {
  return (
    <div className="">
      <OccupationTable />
    </div>
  );
};

export default Occupation;
