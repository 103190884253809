// export const API_BASE_URL_USER = "https://192.168.2.104:4500/api/v1/auth/";
// export const API_BASE_URL_COMPANY = "http://localhost:4500/api/v1/admin/";
// export const API_BASE_URL = "http://localhost:4500/api/v1/auth/";

export const API_BASE_URL_USER = "https://instazubi.de/api/v1/auth/";
export const API_BASE_URL_COMPANY = "https://instazubi.de/api/v1/admin/";
export const API_BASE_URL = "https://instazubi.de/api/v1/auth/";

// export const API_BASE_URL_USER = "https://192.168.178.32:4500/api/v1/auth/";
// export const API_BASE_URL_COMPANY = "http://localhost:4500/api/v1/admin/";
// export const API_BASE_URL = "http://localhost:4500/api/v1/auth/";
