import React from "react";
import AddCompanyDetails from "./components/AddCompanyDetails";

const CompanyDetails = () => {
  return (
    <>
      <AddCompanyDetails />
    </>
  );
};

export default CompanyDetails;
