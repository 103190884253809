import AddInfoHub from "./AddInfoHub";
import GetInfoHub from "./GetInfoHub";

const InfoHubMain = () => {
  return (
    <div>
      <AddInfoHub />
      <GetInfoHub />
    </div>
  );
};

export default InfoHubMain;
