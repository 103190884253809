const Footer = () => {
  return (
    <div className="flex h-full w-full flex-row items-center justify-center rounded-2xl bg-white shadow-lg lg:px-8 xl:flex-row">
      <h5 className="w-full py-5 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
        ©{1900 + new Date().getYear()} EduTecs-Instazubi. Alle Rechte
        vorbehalten.
      </h5>
    </div>
  );
};

export default Footer;
