import React from "react";
import CompanyDetails from "./CompanyDetails";

const Details = () => {
  return (
    <div>
      <CompanyDetails />
    </div>
  );
};

export default Details;
