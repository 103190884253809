import React from "react";
import AddUser from "./AddUser";

const Users = () => {
  return (
    <>
      <AddUser />
    </>
  );
};

export default Users;
