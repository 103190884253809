import React from "react";
import PostJobs from "./PostJobs";
const Jobs = () => {
  return (
    <>
      <PostJobs />
    </>
  );
};

export default Jobs;
