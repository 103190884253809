import { IoCloseCircle } from "react-icons/io5";
import { MdBarChart, MdLocationCity } from "react-icons/md";
import Widget from "components/widget/Widget";
import { getStats } from "api/admin/stats";
import { useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import { BsBuilding } from "react-icons/bs";
import { RiAccountPinCircleLine } from "react-icons/ri";
import MostSelected from "./components/MostSelected";
import { FaLuggageCart } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import RegistrationLocation from "./components/RegistrationLocations";

const MainDashboard = () => {
  const [statsData, setStatsData] = useState(null);
  const totalLocations = statsData?.registrationsLocations?.length || 0;

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await getStats();
        setStatsData(response.data.data);
        // console.log(response.data.data);
      } catch (error) {
        // console.error(error);
      }
    };

    fetchCompanyDetails();
  }, []);

  return (
    <div>
      <div className="mt-6 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<FiUsers className="h-7 w-7" />}
          title={"Benutzer gesamt"}
          subtitle={statsData?.totalUsers}
        />
        <Widget
          icon={<BsBuilding className="h-6 w-6" />}
          title={"Unternehmen gesamt"}
          subtitle={statsData?.totalCompanies}
        />
        <Widget
          icon={<TiTick className="h-6 w-6" />}
          title={"Zugelassene Unternehmen"}
          subtitle={
            statsData?.approveCompany !== undefined
              ? statsData?.approveCompany
              : "0"
          }
        />
        <Widget
          icon={<RiAccountPinCircleLine className="h-7 w-7" />}
          title={"Konten gesamt"}
          subtitle={statsData?.totalUsers + statsData?.totalCompanies}
        />
        <Widget
          icon={<MdLocationCity className="h-6 w-6" />}
          title={"Anmeldungen pro Standort"}
          subtitle={totalLocations}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Terminzusagen"}
          subtitle={
            statsData?.appointmentAccepted[0]?.isApproved
              ? statsData?.appointmentAccepted[0]?.count
              : "0"
          }
        />
        <Widget
          icon={<IoCloseCircle className="h-6 w-6" />}
          title={"Terminabsagen"}
          subtitle={
            statsData?.appointmentDecline[1]?.isReject
              ? statsData?.appointmentDecline[1]?.count
              : "0"
          }
        />
        <Widget
          icon={<FaLuggageCart className="h-6 w-6" />}
          title={"Nicht zum Termin erschienen"}
          subtitle={statsData?.notArrivedAtAppointment[0]?.count}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <MostSelected topCompanies={statsData?.mostSelectedCompany} />
        <RegistrationLocation
          registrationLocations={statsData?.registrationsLocations}
        />
      </div>

      {/* Tables & Charts */}
    </div>
  );
};

export default MainDashboard;
